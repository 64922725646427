import React from 'react';

import Article from './../components/Article';
import Footer from './../components/Footer';

import articles from './../images/articles.jpg';

import './../css/Articles.css';

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: []
    };
  }
  componentDidMount() {
    window.scrollTo(0,0);

    fetch('https://hedayacms.herokuapp.com/articles?_sort=createdAt:desc', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		}).then((response) => {
			return response.json();
		}).then((body) => {
      console.log(body);
			this.setState({articles: body});
		});
  }

  getVidId(link) {
    if(link) {
      var video_id = link.split('v=')[1];
      var ampersandPosition = video_id.indexOf('&');
      if(ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
        return video_id;
      }
      else
      {
        return video_id;
      }
    }
    return null;
  }

  render() {
    return (
      <div className="articlesContainer">
        <div className="articlesHeader">
          <p className="articlesTitle sofiaMedium">
            Articles & Videos
          </p>
        </div>
        <img className="articlesHeaderImg" src={articles} alt="Articles"/>
        {this.state.articles.map((item, index) =>
          <Article
            key={`a${index}`}
            img={item.cover ? item.cover.url : null}
            title={item.Title}
            desc={item.Desc}
            body={item.Body}
            author={item.author}
            time={item.published_at}
            youtubeId={item.youtubeLink}
            id={item._id}
            backgroundColor={index % 2 === 0 ? '#ffffff' : '#E1E1E1'}
          />
        )}
        <Footer/>
      </div>
    )
  }
}

export default Articles;