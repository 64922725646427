import React from 'react';

import './../css/Landing.css';

var classNames = require('classnames');

var throttle = false;

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      offset: '0%'
    };
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
  }
  
  handleScroll(event) {
    const window = event.currentTarget;

    const y = window.scrollY;
    const h = window.innerHeight;

    var offset;
    var percent;

    if(!throttle && y < h)
    {
      throttle = true;
      if(y < h)
      {
        offset = (h-y)/h*100 - 100;
        percent = offset.toFixed(1);
        this.setState({offset: `${percent}%`}, () => {
          throttle = false;
        });
      }
    }
  }
  
  render() {
    var landingTitleClass = classNames({
      'landingTitle': true,
      'sofiaMedium': true,
      'unanimated': !this.props.display
    });

    var landingSubClass = classNames({
      'landingSub': true,
      'sofiaLight': true,
      'unanimated': !this.props.display
    });

    return (
      <div className="landingBackground" style={{backgroundPositionY: this.state.offset}}>
        <div className="landingContent">
          <p className={landingTitleClass}>Josh Hedaya</p>
          <p className={landingSubClass}>Archetypal Astrology: Learn Your Life's Purpose</p>
        </div>
      </div>
    )
  }
}

export default Landing;