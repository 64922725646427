import React from 'react';

import Testimonial from './../components/Testimonial';
import Footer from './../components/Footer';

import './../css/Testimonials.css';

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: []
    }
  }
  componentDidMount() {
    window.scrollTo(0,0);
    fetch('https://hedayacms.herokuapp.com/testimonials?_sort=createdAt:desc', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		}).then((response) => {
			return response.json();
		}).then((body) => {
			this.setState({testimonials: body});
		});
  }

  render() {
    return (
      <div className="testimonialsContainer">
        <div className="testimonialsHeader">
          <p className="testimonialsTitle sofiaMedium">Testimonials</p>
        </div>
        {this.state.testimonials.map((item, index) =>
          <Testimonial
            key={`t${index}`}
            name={item.Name}
            body={item.Body}
            backgroundColor={index%2 === 0 ? '#ffffff' : '#E1E1E1'}
          />  
        )}
        <Footer/>
      </div>
    )
  }
}

export default Testimonials;