import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import YouTube from 'react-youtube';

import './../css/Article.css';

var classNames = require('classnames');

class Article extends React.Component {
  videoOnReady(event) {
    // event.target.pauseVideo();
  }

  render() {
    var articleTextClass = classNames({
      'articleText': this.props.img || this.props.youtubeId,
      'articleTextFull': !this.props.img && !this.props.youtubeId
    });

    return (
      <div className="articleContainer" style={{backgroundColor: this.props.backgroundColor}}>
        <div className="articleContent">
          {this.props.img &&
            <div className="articleImg" style={{backgroundImage: `url(${this.props.img})`}}></div>
          }
          {(this.props.youtubeId && !this.props.img) &&
            <YouTube
              videoId={this.props.youtubeId}
              className={'articleVideo'}
              onReady={this.videoOnReady}
            />
          }
          <div className={articleTextClass}>
            <p className="articleTitle sofiaLight">
              {this.props.title}
            </p>
            <p className="articleAttr sofiaLight">
              {this.props.author} - {moment(this.props.time).format('M/D/YYYY')}
            </p>
            <p className="articleDesc sofiaLight">
              {this.props.desc}
            </p>
            <NavLink className="articleLink sofiaLight" to={`/articles/${this.props.id}`}>
              {this.props.youtubeId ? "Watch" : "Read"}
            </NavLink>
          </div>
        </div>  
      </div>
    )
  }
}

export default Article;