import React from 'react';

import './../css/Testimonial.css';

class Testimonial extends React.Component {
  render() {
    return (
      <div className="testimonialContainer" style={{backgroundColor: this.props.backgroundColor}}>
        <div className="testimonialContent">
          <div className="testimonialHeader">
            <p className="testimonialName sofiaLight">{this.props.name}</p>
          </div>
          <div className="testimonialBody">
            <div className="testimonialBodyText sofiaLight" dangerouslySetInnerHTML={{__html: this.props.body}}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Testimonial;