import React from 'react';

import Service from './../components/Service';

import './../css/Services.css';

import josh2 from './../images/josh2.png';

var classNames = require('classnames');

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
    this.services = React.createRef();
    this.state = {
      service: 0,
      width: window.innerWidth
    }
  }

  componentDidMount() {
    this.props.onRef(this);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(event) {
    this.setState({width: window.innerWidth});
  }

  serviceClick(index) {
    this.setState({service: this.state.service === index ? -1 : index});
  }

  render() {
    var servicesContentClass = classNames({
      'servicesContent': true,
      'unanimated': !this.props.display
    });

    return (
      <div className="servicesContainer" ref={this.services}>
        <div className={servicesContentClass}>
          <div className="pricingText">
            <div className="pricingTitle">
              <p className="pricing sofiaRegular">
                Pricing
              </p>
              <p className="price sofiaLight">
                $225 / hour
              </p>
            </div>
            <Service
              backgroundColor={"#262746"}
              who={"First Time Client"}
              title={"Natal Chart Reading"}
              desc={"Using the natal chart, we start by exploring the common stories, challenges or themes in an individuals life, unpacking them through an archetypal lense. Unlike Sun Sign Astrology that can be found in the back of a newspaper or digital subscription, your natal chart is like a fingerprint, no one else in the world has your chart. And because there is no such thing as a 'good' or a 'bad' chart, once we understand the archetypal energy emanating from your natal chart, it becomes easy to imagine and discover new ways of living your story. It is a solution based approach to a life!"}
              calendly={"https://calendly.com/joshhedaya/60min"}
              open={this.state.service}
              index={0}
              onClick={() => this.serviceClick(0)}
            />
            <Service
              backgroundColor={"#026DA1"}
              who={"Existing Client"}
              title={"Updated Natal Chart"}
              desc={"This consultation helps the Client get a better understanding of events and moments in the past, present, and future through the use of transist's. It is especially useful during major changes and upheaval, and for re-aligning yourself with your Calling or Purpose. Through the use of astrological mapping techniques, update charts can include finding an appropriate place for you to live. I am also available to work with you or your therapist on any specific issue."}
              calendly={"https://calendly.com/joshhedaya/one-hour-natal-chart-reading-clone"}
              index={1}
              open={this.state.service}
              onClick={() => this.serviceClick(1)}
            />
            <Service
              backgroundColor={"#014786"}
              who={"Two Existing Clients"}
              title={"Partnership Chart Reading"}
              desc={"This helps you understand, and deeply, examine, a particular relationship in your life. The relationship can be intimate, in business, a friendship, or with a family member. For a partnership reading I will first read the Natal Chart for each partner separately. Then, I can read a composite chart for both at the same time."}
              calendly={"https://calendly.com/joshhedaya/updating-transit-reading-clone"}
              index={2}
              open={this.state.service}
              onClick={() => this.serviceClick(2)}
            />
          </div>
          <div className="servicesImgContainer">
            <img className="servicesImg" src={josh2} alt="Josh Hedaya"/>
          </div>
        </div>
      </div>
    )
  }
}

export default Services;