import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Nav from './components/Nav';
import Home from './pages/Home';
import Testimonials from './pages/Testimonials';
import About from './pages/About';
import Articles from './pages/Articles';
import Article from './pages/Article';
import Error from './pages/Error';

import './css/Fonts.css';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Nav />
        <Switch>
          <Route path="/" component={Home} exact/>
          <Route path="/testimonials" component={Testimonials} exact/>
          <Route path="/about" component={About} exact/>
          <Route path="/articles" component={Articles} exact/>
          <Route path="/articles/:id" component={Article} exact/>
          <Route component={Error}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
