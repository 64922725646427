import React from 'react';
import * as _ from 'underscore';

import Landing from './../components/Landing';
import Carousel from './../components/Carousel';
import Feature from './../components/Feature';
import Services from './../components/Services';
import Instagram from './../components/Instagram';
import Footer from './../components/Footer';

import fingerprints from './../images/fingerprints.jpeg';
import chart from './../images/chart.jpg';

import './../css/Home.css';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.handleResize = _.throttle(this.handleResize.bind(this), 200);
    this.handleScroll = _.throttle(this.handleScroll.bind(this), 200);
    this.state = {
      width: window.innerWidth,

      landing: false,
      carousel: false,
      f1: false,
      f2: false,
      services: false,
      insta: false
    };
  }

  componentDidMount() {
    window.scrollTo(0,0);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    this.handleResize();
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleResize(event) {
    this.setState({width: window.innerWidth});
  }

  handleScroll(event) {
    const carouselOffset = this.carousel.carousel.current.offsetTop;
    const f1Offset = this.f1.feature.current.offsetTop;
    const f2Offset = this.f2.feature.current.offsetTop;
    const servicesOffset = this.services.services.current.offsetTop;
    const instaOffset = this.insta.insta.current.offsetTop;

    const carouselHeight = this.carousel.carousel.current.offsetHeight;
    const f1Height = this.f1.feature.current.offsetHeight;
    const f2Height = this.f2.feature.current.offsetHeight;
    const servicesHeight = this.services.services.current.offsetHeight;
    const instaHeight = this.insta.insta.current.offsetHeight;

    this.setState({width: window.innerWidth});

    if(!this.state.landing && window.scrollY < window.innerHeight/2)
    {
      this.setState({landing: true});
    }
    else if(!this.state.carousel && this.isInViewport(carouselOffset, carouselHeight, 250))
    {
      this.setState({carousel: true});
    }
    else if(!this.state.f1 && this.isInViewport(f1Offset, f1Height, 250))
    {
      this.setState({f1: true});
    }
    else if(!this.state.f2 && this.isInViewport(f2Offset, f2Height, 250))
    {
      this.setState({f2: true});
    }
    else if(!this.state.services && this.isInViewport(servicesOffset, servicesHeight, 250))
    {
      this.setState({services: true});
    }
    else if(!this.state.insta && this.isInViewport(instaOffset, instaHeight, 250))
    {
      this.setState({insta: true});
    }
  }

  /**
   * Parameters
   * offset: position of top of container
   * height: used to calculate bottom of container
   * buffer: how far into the container you must scroll, in either direction, before it returns that the container is far enough into the viewport
   * 
   * Returns
   * bool: whether container is in viewport, accounting for maximum offset of the buffer in each direction
   */
  isInViewport(offset, height, buffer)
  {
    const y = window.scrollY;
    const h = window.innerHeight;
    let upperMid;
    let lowerMid;

    //If the height of the container is less than 500, the center point is used for both bounds
    //If the height of the container is greater than 500, the upper bound is set to the top plus 250 and the bottom is set to the bottom minus 250
    if(height < buffer*2)
    {
      upperMid = offset + height/2;
      lowerMid = offset + height/2;
    }
    else
    {
      upperMid = offset + buffer;
      lowerMid = offset + height - buffer;
    }

    //Check if either the upper bound or the lower bound is within the viewport (defined as being between the pages scroll position and the scroll position plus the height of the window)
    if((upperMid > y && upperMid < y+h) || (lowerMid > y && lowerMid < y+h))
    {
      return true;
    }
    return false;
  }

  render() {
    return (
      <>
        <Landing
          display={this.state.landing}
        />
        <Feature
          onRef={(ref) => {this.f1 = ref}}
          display={this.state.f1}
          backgroundColor={"#ffffff"}//026DA1
          textColor={"#000000"}
          flexDirection={this.state.width > 992 ? 'row' : 'column'}
          title={"THE POWER OF AN ASTROLOGY READING: ONE HOUR CAN CHANGE YOUR LIFE, REALLY"}
          lineItems={[
            {
              bold: "Learn",
              text: " it - awaken to your own personal story with a natal chart reading."
            },
            {
              bold: "Live",
              text: " it - work in flow with your greatest character strengths while understanding your blind spots."
            },
            {
              bold: "Love",
              text: " it - act in alignment within your personal story and flourish living your life's purpose."
            }
          ]}
          image={chart}
        />
        <Carousel
          onRef={(ref) => {this.carousel = ref}}
          display={this.state.carousel}
        />
        <Feature
          onRef={(ref) => {this.f2 = ref}}
          display={this.state.f2}
          backgroundColor={"#ffffff"}
          textColor={"#000000"}
          flexDirection={this.state.width > 992 ? 'row-reverse' : 'column'}
          title={"GIVE YOURSELF PERMISSION TO BE WHO YOU TRULY ARE"}
          // lineItems={[
          //   {
          //     bold: "Awaken",
          //     text: " to your own personal story"
          //   },
          //   {
          //     bold: "Align",
          //     text: " yourself with your greatest strengths and life purpose"
          //   },
          //   {
          //     bold: "Act",
          //     text: " to finally live the life you've been called to"
          //   }
          // ]}
          desc={'Each of us was born with an astrological map, a source code that reveals our natural make-up. Within your map are individualized human patterns, archetypes, that reveal your greatest strengths, weaknesses, and life\'s purpose.'}
          image={fingerprints}
        />
        {/* <Feature
          onRef={(ref) => {this.f2 = ref}}
          display={this.state.f2}
          flexDirection={this.state.width > 992 ? 'row' : 'column'}
          desc={'Cras ultricies ligula sed magna dictum porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada.'}
          image={lightbulb}
        /> */}
        <Services
          onRef={(ref) => {this.services = ref}}
          display={this.state.services}
        />
        <Instagram
          onRef={(ref) => {this.insta = ref}}
          display={this.state.insta}
        />
        <Footer/>
      </>
    )
  }
}

export default Home;