import React from 'react';

import Slider from "react-slick";

import './../css/Carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

var classNames = require('classnames');

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.carousel = React.createRef();
    this.state = {
      testimonials: []
    }
  }

  componentDidMount() {
    this.props.onRef(this);
    fetch('https://hedayacms.herokuapp.com/testimonials?ShowOnHomepage=true', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		}).then((response) => {
			return response.json();
		}).then((body) => {
			this.setState({testimonials: body});
		});
  }

  render() {
    var carouselContentClass = classNames({
      'carouselContent': true,
      'unanimated': !this.props.display
    });

    var settings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 1200,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
      outline: 'none'
    };

    return (
      <div className="carouselContainer">
        <div className={carouselContentClass} ref={this.carousel}>
        {this.state.testimonials.length > 0 &&
          <Slider {...settings}>
            {this.state.testimonials.map((item, index) =>
              <div className="noOutline">
                <div className="homeTestimonialContent">
                  <div className="homeTestimonialQuote sofiaLight" dangerouslySetInnerHTML={{__html: item.Body}}></div>
                  <p className="homeTestimonialAttr sofiaBold">{item.Name}</p>
                </div>
              </div>
            )}
          </Slider>
        }
        </div>
      </div>
    )
  }
}

export default Carousel