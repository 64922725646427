import React from 'react';
import { NavLink } from 'react-router-dom';
import { openPopupWidget } from 'react-calendly';

import './../css/Footer.css';

// import Logo from './../images/logo.png';

// eslint-disable-next-line
const emailPattern = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			name: '',
      email: '',
      number: '',
			message: '',
			
			inputsDisabled: false,
			responseMessage: ''
		};
  }

  calendlyClick() {
    openPopupWidget({url: 'https://calendly.com/joshhedaya'});
  }

  sendMessage() {
    this.setState({inputsDisabled: true, responseMessage: ""}, () => {
			const {
				name,
        email,
        number,
				message,
      } = this.state;
      if(name === '' || email === '' || message === '' || number === '' /*|| (!creativeProduction && !documentation && !featuredFilmDocumentary && !socialDigitalCampaign && !strategyContentCreation && !other)*/)
			{
				this.setState({inputsDisabled: false, responseMessage: "All fields are required"});
			}
			else if(!emailPattern.test(email))
			{
				this.setState({inputsDisabled: false, responseMessage: "Please enter a valid email address"});
      }
      else
			{
				fetch('/message', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						name: name,
            email: email,
            number: number,
						message: message
					})
				}).then((response) => {
					return response.json();
				}).then((body) => {
					if(body.message === "error")
					{
						this.setState({inputsDisabled: false, responseMessage: "There was an error, please try again later or email us directly at joshua.hedaya@gmail.com"});
					}
					else
					{
						this.setState({responseMessage: "Thanks for reaching out!  I'll get back to you ASAP."});
					}
        });
      }
    });
  }

  render() {
    return (
      <div className="footerContainer">
        <div className="footerContent">
          <div className="footerInfo">
            <div className="footerTitleContainer">
              {/* <img className="footerLogo" src={Logo} alt="Joshua Hedaya"/> */}
              <div className="footerNameContainer">
                <p className="footerName sofiaRegular">
                  Joshua Hedaya
                </p>
                <p className="footerSub sofiaLight">Archetypal Astrology</p>
              </div>
            </div>
            <div className="footerLinks sofiaExtraLight">
              <NavLink className="footerLink" to="/">
                <p className="footerLinkText">Home</p>
              </NavLink>
              <NavLink className="footerLink" to="/about">
                <p className="footerLinkText">About</p>
              </NavLink>
              <NavLink className="footerLink" to="/testimonials">
                <p className="footerLinkText">Testimonials</p>
              </NavLink>
              <NavLink className="footerLink" to="/articles">
                <p className="footerLinkText">Articles/Videos</p>
              </NavLink>
              <div className="footerLink" onClick={() => this.calendlyClick()}>
                <p className="footerLinkText">Schedule</p>
              </div>
            </div>
            <div className="contactInfoContainer">
              <p className="contactInfo sofiaExtraLight">joshua.hedaya@gmail.com</p>
              <p className="contactInfo sofiaExtraLight">202-758-9183</p>
            </div>
          </div>
          <div className="footerForm">
            <p className="footerMessage sofiaRegular">Leave a message</p>
            <input
              className="formInput sofiaRegular"
              placeholder="Name"
              value={this.state.name}
              onChange={(e) => this.setState({name: e.target.value})}
              disabled={this.state.inputsDisabled}
              style={{
                backgroundColor: this.state.inputsDisabled ? '#eeeeee' : '#ffffff',
                color: this.state.inputsDisabled ? '#aaaaaa' : '#4e4e4e'
              }}
            />
            <input
              className="formInput sofiaRegular"
              placeholder="Email"
              value={this.state.email}
              onChange={(e) => this.setState({email: e.target.value})}
              disabled={this.state.inputsDisabled}
              style={{
                backgroundColor: this.state.inputsDisabled ? '#eeeeee' : '#ffffff',
                color: this.state.inputsDisabled ? '#aaaaaa' : '#4e4e4e'
              }}
            />
            <input
              className="formInput sofiaRegular"
              placeholder="Number"
              value={this.state.number}
              onChange={(e) => this.setState({number: e.target.value})}
              disabled={this.state.inputsDisabled}
              style={{
                backgroundColor: this.state.inputsDisabled ? '#eeeeee' : '#ffffff',
                color: this.state.inputsDisabled ? '#aaaaaa' : '#4e4e4e'
              }}
            />
            <textarea
              className="formInput sofiaRegular"
              placeholder="Message"
              rows={4}
              value={this.state.message}
              onChange={(e) => this.setState({message: e.target.value})}
              disabled={this.state.inputsDisabled}
              style={{
                backgroundColor: this.state.inputsDisabled ? '#eeeeee' : '#ffffff',
                color: this.state.inputsDisabled ? '#aaaaaa' : '#4e4e4e'
              }}
            >
            </textarea>
            <p className="formMessage sofiaLight">{this.state.responseMessage}</p>
            <button
              className="formSubmit sofiaExtraLight"
              onClick={() => this.sendMessage()}
              disabled={this.state.inputsDisabled}
              style={{
                backgroundColor: this.state.inputsDisabled ? 'rgb(78, 120, 161)' : '#3281D1',
                color: this.state.inputsDisabled ? '#eeeeee' : '#ffffff'
              }}
            >
              Send  
            </button>
          </div>
        </div>
        <div className="signature">
          <p className="signatureText sofiaExtraLight">developed by <a className="signatureFortitude sofiaLight" href="https://www.fortitudedev.com" target="_blank" rel="noopener noreferrer">Fortitude</a></p>
        </div>
      </div>
    )
  }
}

export default Footer;