import React from 'react';
import { openPopupWidget } from 'react-calendly';

import './../css/Service.css';

var classNames = require('classnames');

class Service extends React.Component {
  calendlyClick(e) {
    e.stopPropagation();
    openPopupWidget({url: this.props.calendly});
  }

  render() {
    var serviceContainerClass = classNames({
      'serviceContainer': true,
      'serviceContainerOpen': this.props.open === this.props.index
    });

    var caretClass = classNames({
      'caret': true,
      'sofiaLight': true,
      'caretOpen': this.props.open === this.props.index
    });
    
    return (
      <div className={serviceContainerClass} style={{backgroundColor: this.props.backgroundColor}} onClick={() => this.props.onClick()}>
        <div className="serviceHeaderRow">
          <p className={caretClass}>v</p>
          <div className="serviceMobileContainer">
            <p className="serviceWho sofiaLight">{this.props.who}</p>
            <p className="serviceTitleMobile sofiaLight">{this.props.title}</p>
          </div>
          <div className="serviceHeaderRight">
            <p className="serviceTitle sofiaRegular">{this.props.title}</p>
            <button className="scheduleButton sofiaExtraLight" onClick={(e) => this.calendlyClick(e)}>
              Schedule
            </button>
          </div>
        </div>
        <div className="serviceContent">
          <p className="serviceDesc sofiaLight">{this.props.desc}</p>
        </div>
      </div>
    )
  }
}

export default Service;