import React from 'react';

import './../css/Instagram.css';

var classNames = require('classnames');

class Instagram extends React.Component {
  constructor(props) {
    super(props);
    this.insta = React.createRef();
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  render() {
    var instagramContainerClass = classNames({
      'instagramContainer': true,
      'unanimated': !this.props.display
    });

    return (
      <div className={instagramContainerClass} ref={this.insta}>
        <a className="instagramTitle sofiaRegular" href="https://www.instagram.com/archetypal_astrology/">
          @archetypal_astrology
        </a>
        <div className="elfsight-app-0df3bc35-d9d5-49e3-8b7f-31ff77521c0c"></div>
      </div>
    )
  }
}

export default Instagram;