import React from 'react';

import Footer from './../components/Footer';

import josh from './../images/about.png';

import './../css/About.css';

class About extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    return (
      <div className="aboutContainer">
        <div className="aboutContent">
          <p className="aboutTitle sofiaRegular">About Josh</p>
          <img className="aboutImg" src={josh} alt="Josh Hedaya"/>
          <p className="aboutBody sofiaLight">
            "Just schedule an astrology reading, trust me, you won't regret it."
          </p>
          <p className="aboutBody sofiaLight">
            Little did I know that that call from my father, over a decade ago, would change my life forever, allowing me
            to help hundreds of people across the globe, from Hawaii to Israel, teach seminars throughout Southern
            California, and find my life’s calling: teaching my client’s theirs, using the ancient technology that is
            astrology.
          </p>
          <p className="aboutBody sofiaLight">
            My background is in the political space, about as far removed from astrology as one can get. I've worked for
            U.S. Senators, Governors, Congressman, major corporations, energy lobbyists, and served on several public sector
            councils, I even have a Masters in Political Science!
          </p>
          <p className="aboutBody sofiaLight">
            I believe whole heartedly in the practicality of life and our free will. Your astrological natal chart is not
            fated, and one of the gifts of the work I provide is practical exercises to work through any roadblock that is
            standing in your way, from relationship troubles, health problems to career ambiguity, believe it or not, this
            language can effectively address all of the above!
          </p>
          <p className="aboutBody sofiaLight">
            Unlike a life coach or traditional therapy, which can take several sessions to get to root challenges, a natal
            chart reading gets right to the heart of the matter. Within minutes you can identify the major obstacles holding
            you back and realign yourself with your life's purpose. "How could that be?" you might ask, well, a natal chart
            is an X-ray of your soul, what you came here to do.
          </p>
          <p className="aboutBody sofiaLight">
            This is why I believe in the power of an astrology reading.
          </p>
          <p className="aboutBody sofiaLight">
            ONE HOUR CAN CHANGE YOUR LIFE, REALLY
          </p>
          <p className="aboutBody sofiaLight">
            <span className="aboutItalic aboutBold">Learn</span> it - awaken to your own personal story with a natal chart reading.<br/>
            <span className="aboutItalic aboutBold">Live</span> it - work in flow with your greatest character strengths while understanding your blind spots.<br/>
            <span className="aboutItalic aboutBold">Love</span> it - act in alignment within your personal story and flourish living your life's purpose.<br/>
          </p>
          <p className="aboutBody sofiaLight">
            I look forward to hearing from you and helping you!
          </p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default About;