import React from 'react';

import './../css/Feature.css';

var classNames = require('classnames');

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.feature = React.createRef();
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  render() {
    var featureContainerClass = classNames({
      'featureContainer': true,
      'unanimated': !this.props.display
    });

    return (
      <div style={{backgroundColor: this.props.backgroundColor}}>
        <div className={featureContainerClass} ref={this.feature} style={{flexDirection: this.props.flexDirection}}>
          <img className="featureImage" src={this.props.image} alt=""/>
          <div className="featureContent sofiaLight">
            <p className="featureTitle" style={{color: this.props.textColor, borderBottomColor: this.props.textColor}}>{this.props.title}</p>
            <p className="featureDesc" style={{color: this.props.textColor}}>{this.props.desc}</p>
            {this.props.lineItems &&
              this.props.lineItems.map((item, index) =>
                <p className="featureDesc" style={{color: this.props.textColor}}><span className="featureDescBold">{item.bold}</span>{item.text}</p>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Feature;