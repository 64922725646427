import React from 'react';
import moment from 'moment';
import YouTube from 'react-youtube';

import Footer from './../components/Footer';

import './../css/ArticlePage.css';

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {}
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
    
    fetch(`https://hedayacms.herokuapp.com/articles/${this.props.match.params.id}`, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		}).then((response) => {
			return response.json();
		}).then((body) => {
      console.log(body);
			this.setState({article: body});
		});
  }

  videoOnReady(event) {
    // event.target.pauseVideo();
  }

  getVidId(link) {
    if(link) {
      var video_id = link.split('v=')[1];
      var ampersandPosition = video_id.indexOf('&');
      if(ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
        return video_id;
      }
      else
      {
        return video_id;
      }
    }
    return null;
  }

  render() {
    return (
      <div className="articlePageContainer">
        <div className="articlePageContent">
          {this.state.article === {} ||
            <div className="postContent">
              <p className="postTitle sofiaRegular">{this.state.article.Title}</p>
              <p className="postAuthor">{this.state.article.author} - {moment(this.state.article.published_at).format('M/D/YYYY')}</p>
              {(!this.state.article.youtubeLink && this.state.article.cover) &&
                <div className="postImg" style={{backgroundImage: `url(${this.state.article.cover.url})`}}></div>
              }
              {this.state.article.youtubeLink && 
                <YouTube
                  videoId={this.state.article.youtubeLink}
                  className={'postVideo'}
                  onReady={this.videoOnReady}
                />
              }
              <div className="postBody sofiaLight" dangerouslySetInnerHTML={{__html: this.state.article.Body}}></div>
            </div>
          }
        </div>
        <Footer/>
      </div>
    )
  }
}

export default Article;