import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { openPopupWidget } from 'react-calendly';

import './../css/Nav.css';

var classNames = require('classnames');

class Nav extends React.Component {
  state = {
    navWhite: false,
    open: false
  }
  
  componentDidMount() {
    if(this.props.location.pathname === '/')
    {
      this.setState({navWhite: true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if(this.props.location.pathname === '/')
      {
        this.setState({navWhite: true});
      }
      else
      {
        this.setState({navWhite: false});
      }
    }
  }

  calendlyClick() {
    openPopupWidget({url: 'https://calendly.com/joshhedaya'});
  }

  linkClicked() {
    this.setState({
      open: false
    });
  }

  openMenu() {
    this.setState({open: true});
  }

  render() {
    var navLinkTextClass = classNames({
      'navLinkText': true,
      'navLinkTextWhite': this.state.navWhite
    });

    var navLinkUnderlineClass = classNames({
      'navLinkUnderline': true,
      'navLinkUnderlineWhite': this.state.navWhite
    });

    var mobileNavMenuClass = classNames({
      'mobileNavMenu': true,
      'mobileNavMenuOpen': this.state.open
    });

    return (
      <div className="navContainer">
        <div className="navDesktop">
          <div></div>
          <div className="navContent sofiaExtraLight">
            <NavLink className="navLink" to="/" onClick={() => this.linkClicked()}>
              <p className={navLinkTextClass}>Home</p>
              <div className={navLinkUnderlineClass}></div>
            </NavLink>
            <NavLink className="navLink" to="/about" onClick={() => this.linkClicked()}>
              <p className={navLinkTextClass}>About</p>
              <div className={navLinkUnderlineClass}></div>
            </NavLink>
            <NavLink className="navLink" to="/testimonials" onClick={() => this.linkClicked()}>
              <p className={navLinkTextClass}>Testimonials</p>
              <div className={navLinkUnderlineClass}></div>
            </NavLink>
            <NavLink className="navLink" to="/articles" onClick={() => this.linkClicked()}>
              <p className={navLinkTextClass}>Articles/Videos</p>
              <div className={navLinkUnderlineClass}></div>
            </NavLink>
            <div className="navLink navScheduleButton" onClick={() => this.calendlyClick()}>
              <p className="navLinkButtonText">Schedule</p>
              <div className="navLinkButtonUnderline"></div>
            </div>
          </div>
        </div>
        <div className="navMobile sofiaRegular">
          <div className="navHamburger" onClick={() => this.openMenu()}>
            <div className="burgerBar"></div>
            <div className="burgerBar"></div>
          </div>
          <div className={mobileNavMenuClass}>
            <div className="navDismiss sofiaRegular" onClick={() => this.linkClicked()}>
              x
            </div>
            <div className="mobileNavItems">
              <NavLink className="navLink" to="/" onClick={() => this.linkClicked()}>
                <p className="mobileNavLinkText">Home</p>
              </NavLink>
              <NavLink className="navLink" to="/about" onClick={() => this.linkClicked()}>
                <p className="mobileNavLinkText">About</p>
              </NavLink>
              <NavLink className="navLink" to="/testimonials" onClick={() => this.linkClicked()}>
                <p className="mobileNavLinkText">Testimonials</p>
              </NavLink>
              <NavLink className="navLink" to="/articles" onClick={() => this.linkClicked()}>
                <p className="mobileNavLinkText">Articles/Videos</p>
              </NavLink>
              <div className="navLink navScheduleButton mobileNavScheduleButton" onClick={() => this.calendlyClick()}>
                <p className="mobileNavLinkButtonText">Schedule</p>
                <div className="navLinkButtonUnderline"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Nav);